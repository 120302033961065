<template>
    <li v-for="caseStudy in calculatedCaseStudies" v-bind="$attrs">
        <ImageCard :item="caseStudy" :theme="caseStudy.theme" />
    </li>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
})

function getTagsForCaseStudy(capabilities) {
    return capabilities
        .map((item) => {
            if (!item.capability.data) return
            return item.capability.data.capability_name
        })
        .filter((a) => a)
}

const calculatedCaseStudies = computed(() => {
    return props.items.map(({ case_study: caseStudy }) => {
        return {
            image: {
                path: caseStudy.data.hero_image?.small?.url,
                dimensions: caseStudy.data.hero_image?.small?.dimensions,
            },
            title: caseStudy.data.card_title ?? caseStudy.data.title,
            tags: getTagsForCaseStudy(caseStudy.data.capability_tags),
            logo: caseStudy.data.brand?.data && {
                path: caseStudy.data.brand.data.client_logo?.url,
                dimensions: caseStudy.data.brand.data.client_logo?.dimensions,
            },
            to: caseStudy.url,
            theme: caseStudy.data.theme || {},
            cursorText: 'View<br>Project',
        }
    })
})
</script>
