<template>
    <li v-for="caseStudy in calculatedCaseStudies" v-bind="$attrs">
        <ImageCard :item="caseStudy" :theme="caseStudy.theme" />
    </li>
</template>

<script setup>
const prismic = usePrismic()

const props = defineProps({
    theme: { type: Object, default: () => ({}) },
    ignoreThemeColour: { type: Boolean, default: false },
    sliceId: { type: String, default: 'case-study-grid' },
})

function getTagsForCaseStudy(capabilities) {
    return capabilities
        .map((item) => {
            if (!item.capability.data) return
            return item.capability.data.capability_name
        })
        .filter((a) => a)
}

const { data: caseStudiesRes } = await useAsyncData(`[${props.sliceId}]`, () =>
    prismic.client.getByType('case_study', {
        orderings: [
            {
                field: 'my.case_study.original_publish_date',
                direction: 'desc',
            },
            {
                field: 'document.first_publication_date',
                direction: 'desc',
            },
        ],
        filters: [
            ...(props.theme?.id
                ? [prismic.filter.at('my.case_study.theme', props.theme.id)]
                : []),
        ],
        fetchLinks: [
            'capability.capability_name',
            'brand.client_logo',
            'theme.theme_base_colour',
            'theme.theme_chip_colour',
            'theme.theme_logo',
            'theme.theme_icon',
            'theme.theme_chip_icon_colour',
        ],
        pageSize: 4,
    }),
)

const calculatedCaseStudies = computed(() => {
    return caseStudiesRes.value.results.map((caseStudy) => {
        return {
            image: {
                path: caseStudy.data.hero_image.small?.url,
                dimensions: caseStudy.data.hero_image.small?.dimensions,
            },
            title: caseStudy.data.card_title ?? caseStudy.data.title,
            tags: getTagsForCaseStudy(caseStudy.data.capability_tags),
            logo: caseStudy.data.brand?.data && {
                path: caseStudy.data.brand.data.client_logo.url,
                dimensions: caseStudy.data.brand.data.client_logo?.dimensions,
            },
            to: caseStudy.url,
            theme: {
                ...(caseStudy.data.theme || props.theme || {}),
                ignoreThemeColour: props.ignoreThemeColour,
            },
            cursorText: 'View<br>Project',
        }
    })
})
</script>
