<template>
    <section class="CaseStudyGrid" :id="sliceId">
        <div class="CaseStudyGrid__title-wrapper">
            <h2 v-if="slice.primary.section_title" class="CaseStudyGrid__title">
                <FillTextEffect
                    :id="sliceId"
                    :text="slice.primary.section_title"
                    :trigger-container="'#' + sliceId"
                    bottom-position="400px"
                />
            </h2>
        </div>
        <ul class="CaseStudyGrid__items">
            <DefaultVariation
                v-if="props.slice.variation === 'default'"
                :theme="props.slice.primary.theme"
                :ignore-theme-colour="
                    props.slice.primary.ignore_theme_colours_on_cards
                "
                :slice-id="sliceId"
                class="CaseStudyGrid__item"
            />
            <ManualVariation
                v-if="props.slice.variation === 'manual'"
                class="CaseStudyGrid__item"
                :items="props.slice.items"
            />
        </ul>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'
import { SliceVariationComponentsCaseStudyGridDefaultVariation as DefaultVariation } from '#components'
import { SliceVariationComponentsCaseStudyGridManualVariation as ManualVariation } from '#components'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.CaseStudyGridSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const sliceId = props.slice.id.replace('$', '-') + '-case-study-grid'
</script>

<style lang="scss">
.CaseStudyGrid {
    grid-column: 2 / span 22;
    padding-top: 8rem;

    @include large-up {
        padding-top: 11.25rem;
    }
}

.CaseStudyGrid__title {
    @include heading-2;

    margin-bottom: 2.5rem;
    max-width: 36rem;
}

.CaseStudyGrid__title-wrapper {
    width: 100%;
}

.CaseStudyGrid__items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10%;
    row-gap: 5rem;
    justify-content: flex-start;
}

.CaseStudyGrid__item {
    width: 100%;

    &:nth-child(even) {
        @include large-up {
            margin-top: 5rem;
        }
    }

    @media (min-width: 720px) {
        width: 45%;
    }
}
</style>
